@import url('https://fonts.googleapis.com/css2?family=B612&family=Titillium+Web:wght@600&family=Varela&family=Vollkorn:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=B612&family=Karla:wght@500&family=Titillium+Web:wght@600&family=Varela&family=Vollkorn:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0;
    padding: 0;
    /* font-family: Varela, 'sans-serif'; */
    font-family: Varela, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.toggle__dot {
    top: -0.25rem;
    left: -0.25rem;
    transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
    transform: translateX(100%);
    background-color: #48bb78;
}
textarea {
    resize: vertical;
}
nav img {
    image-rendering: crisp-edges;
}

div.chat-column {
    height: 36.5em;
}
.notification-container--top-right,
.notification-container--top-right > .notification {
    @apply rounded-2xl rounded-b-2xl overflow-hidden;
}

/* div.chat-column::-webkit-scrollbar {
	background: transparent;
} */
@layer components {
    ::-webkit-scrollbar {
        background: transparent;
        width: 0.5em;
        @apply bg-none dark:bg-gray-800;
    }
    ::-webkit-scrollbar-thumb {
        /* background-color: rgba(192, 187, 187, 0.87); */
        @apply bg-gray-400 dark:bg-gray-600;
        border-radius: 3em;
    }
    textarea::-webkit-scrollbar {
        @apply bg-none dark:bg-gray-700;
    }
    textarea::-webkit-scrollbar-thumb {
        @apply bg-gray-600 dark:bg-gray-400;
    }
    body {
        @apply dark:bg-gray-800;
    }
    .convo::-webkit-scrollbar {
        @apply bg-transparent;
    }
    .convo::-webkit-scrollbar-thumb {
        @apply dark:bg-gray-800;
    }
    .no-scrollbar::-webkit-scrollbar {
        width: 0rem;
    }
    p.peace i {
        @apply text-blue-500;
        @apply text-2xl;
        left: 0;
        text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc;
    }
}

/* ::-webkit-scrollbar-track {
	color: dimgray;
} */
tr.strikeout td:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
}

tr.strikeout td:after {
    content: '\00B7';
    font-size: 1px;
}
.text-xxs {
    font-size: 0.7em;
    line-height: 0.75em;
}
div.convo {
    max-height: 35em;
    scrollbar-width: none;
    scrollbar-color: dodgerblue;
}

div.other-convo {
    max-height: 85vh;
    min-height: 85vh;
}

.social-links a {
    color: white;
    text-decoration: none;
    border: 0.2em solid white;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.new-features-list > li {
    list-style-type: '🔥';
    padding-inline-start: 1ch;
}

.social-links a:hover {
    border-color: dodgerblue;
}

textarea.chat-input {
    max-height: 5rem;
}
.post-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.noSelect {
    -webkit-tap-highlight-color: transparent;
}

/* change color of next 2 bullets in sequence to white*/

/* .swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
    background-color: white;
} */

/* change color of previous bullet to white*/

/* .swiper-pagination-bullet-active-prev {
    background-color: white;
} */

/*move swiper pagination on top to image */
.swiper-pagination {
    position: relative;
    text-align: center;
    z-index: 10;
}

.DayPickerInput {
    width: 100% !important;
}
svg.name {
    /* transform: translate(-50%, -50%); */
    width: 100%;
}

svg text {
    text-transform: uppercase;
    font-size: 5vw;
    stroke-width: 6;
    stroke: dodgerblue;
    /* #4b6cc1 */
    fill: dodgerblue;
    /* fill: #4b6cc1; */
    animation: stroke 8s ease;
    /* text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc; */
    /* text-shadow: 16px 22px 11px rgba(30, 143, 255, 0.151); */
}

@keyframes stroke {
    0% {
        fill: rgba(240, 193, 6, 0);
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 35%;
        stroke-width: 6;
        stroke: #f0c109;
    }
    20% {
        stroke: #f09f09;
    }
    30% {
        stroke: rgb(202, 70, 9);
    }
    50% {
        stroke: #e07b07;
    }
    70% {
        fill: rgba(240, 193, 7, 0);
    }
    80% {
        fill: rgba(240, 193, 8, 0);
        stroke-width: 5;
        stroke: #0980f0;
    }
    90% {
        fill: rgb(255, 208, 19);
        stroke-width: 5.5;
    }
    100% {
        fill: dodgerblue;
        stroke: dodgerblue;
        stroke-dasharray: 50% 0;
        stroke-width: 6;
    }
}
